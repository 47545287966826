package kotlinx.html

import kotlinx.html.*
import kotlinx.html.impl.*
import kotlinx.html.attributes.*

/*******************************************************************************
    DO NOT EDIT
    This file was generated by module generate
*******************************************************************************/

@Suppress("unused")
open class H1(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("h1", consumer, initialAttributes, null, false, false), CommonAttributeGroupFacadeFlowHeadingPhrasingContent {

}

@Suppress("unused")
open class H2(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("h2", consumer, initialAttributes, null, false, false), CommonAttributeGroupFacadeFlowHeadingPhrasingContent {

}

@Suppress("unused")
open class H3(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("h3", consumer, initialAttributes, null, false, false), CommonAttributeGroupFacadeFlowHeadingPhrasingContent {

}

@Suppress("unused")
open class H4(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("h4", consumer, initialAttributes, null, false, false), CommonAttributeGroupFacadeFlowHeadingPhrasingContent {

}

@Suppress("unused")
open class H5(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("h5", consumer, initialAttributes, null, false, false), CommonAttributeGroupFacadeFlowHeadingPhrasingContent {

}

@Suppress("unused")
open class H6(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("h6", consumer, initialAttributes, null, false, false), CommonAttributeGroupFacadeFlowHeadingPhrasingContent {

}

@Suppress("unused")
open class HEAD(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("head", consumer, initialAttributes, null, false, false), HtmlHeadTag {
    @Deprecated("This tag most likely doesn't support text content or requires unsafe content (try unsafe {}")
    override operator fun Entities.unaryPlus() : Unit {
        @Suppress("DEPRECATION") entity(this)
    }

    @Deprecated("This tag most likely doesn't support text content or requires unsafe content (try unsafe {}")
    override operator fun String.unaryPlus() : Unit {
        @Suppress("DEPRECATION") text(this)
    }

    @Deprecated("This tag most likely doesn't support text content or requires unsafe content (try unsafe {}")
    override fun text(s : String) : Unit {
        super<HTMLTag>.text(s)
    }

    @Deprecated("This tag most likely doesn't support text content or requires unsafe content (try unsafe {}")
    override fun text(n : Number) : Unit {
        super<HTMLTag>.text(n)
    }

    @Deprecated("This tag most likely doesn't support text content or requires unsafe content (try unsafe {}")
    override fun entity(e : Entities) : Unit {
        super<HTMLTag>.entity(e)
    }

}

@Suppress("unused")
open class HEADER(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("header", consumer, initialAttributes, null, false, false), HtmlBlockTag {

}

@Suppress("unused")
open class HGROUP(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("hgroup", consumer, initialAttributes, null, false, false), CommonAttributeGroupFacadeFlowHeadingContent {

}
/**
 * Heading
 */
@HtmlTagMarker
fun HGROUP.h1(classes : String? = null, block : H1.() -> Unit = {}) : Unit = H1(attributesMapOf("class", classes), consumer).visit(block)

/**
 * Heading
 */
@HtmlTagMarker
fun HGROUP.h2(classes : String? = null, block : H2.() -> Unit = {}) : Unit = H2(attributesMapOf("class", classes), consumer).visit(block)

/**
 * Heading
 */
@HtmlTagMarker
fun HGROUP.h3(classes : String? = null, block : H3.() -> Unit = {}) : Unit = H3(attributesMapOf("class", classes), consumer).visit(block)

/**
 * Heading
 */
@HtmlTagMarker
fun HGROUP.h4(classes : String? = null, block : H4.() -> Unit = {}) : Unit = H4(attributesMapOf("class", classes), consumer).visit(block)

/**
 * Heading
 */
@HtmlTagMarker
fun HGROUP.h5(classes : String? = null, block : H5.() -> Unit = {}) : Unit = H5(attributesMapOf("class", classes), consumer).visit(block)

/**
 * Heading
 */
@HtmlTagMarker
fun HGROUP.h6(classes : String? = null, block : H6.() -> Unit = {}) : Unit = H6(attributesMapOf("class", classes), consumer).visit(block)

val HGROUP.asFlowContent : FlowContent
    get()  = this

val HGROUP.asHeadingContent : HeadingContent
    get()  = this


@Suppress("unused")
open class HR(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("hr", consumer, initialAttributes, null, false, true), HtmlBlockTag {

}

@Suppress("unused")
open class HTML(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>, namespace : String? = null) : HTMLTag("html", consumer, initialAttributes, namespace, false, false), CommonAttributeGroupFacade {
    var manifest : String
        get()  = attributeStringString.get(this, "manifest")
        set(newValue) {attributeStringString.set(this, "manifest", newValue)}

    @Deprecated("This tag most likely doesn't support text content or requires unsafe content (try unsafe {}")
    override operator fun Entities.unaryPlus() : Unit {
        @Suppress("DEPRECATION") entity(this)
    }

    @Deprecated("This tag most likely doesn't support text content or requires unsafe content (try unsafe {}")
    override operator fun String.unaryPlus() : Unit {
        @Suppress("DEPRECATION") text(this)
    }

    @Deprecated("This tag most likely doesn't support text content or requires unsafe content (try unsafe {}")
    override fun text(s : String) : Unit {
        super<HTMLTag>.text(s)
    }

    @Deprecated("This tag most likely doesn't support text content or requires unsafe content (try unsafe {}")
    override fun text(n : Number) : Unit {
        super<HTMLTag>.text(n)
    }

    @Deprecated("This tag most likely doesn't support text content or requires unsafe content (try unsafe {}")
    override fun entity(e : Entities) : Unit {
        super<HTMLTag>.entity(e)
    }

}
/**
 * Document body
 */
@HtmlTagMarker
fun HTML.body(classes : String? = null, block : BODY.() -> Unit = {}) : Unit = BODY(attributesMapOf("class", classes), consumer).visit(block)

/**
 * Document head
 */
@HtmlTagMarker
fun HTML.head(block : HEAD.() -> Unit = {}) : Unit = HEAD(emptyMap, consumer).visit(block)
@Deprecated("This tag doesn't support content or requires unsafe (try unsafe {})")
@Suppress("DEPRECATION")
/**
 * Document head
 */
@HtmlTagMarker
fun HTML.head(content : String = "") : Unit = HEAD(emptyMap, consumer).visit({+content})


